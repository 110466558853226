import '@remento-infrastructure/integrations/@types.ce.js';

import logger from '@remento-infrastructure/common/logger.js';
import camelCase from 'camelcase';

import { Router } from '../router.js';

export interface CrazyEggExperiment {
  id: string;
  name: string;
  variantType: 'control' | 'variant' | null;
  variantId: string;
}

export interface CrazyEggEventPayload {
  ceExperiments: Array<string>;
  [experiment: string]: string | Array<string>;
}

const DEVICE_TYPE = {
  1: 'desktop',
  2: 'phone',
  3: 'tablet',
} as const;

export function getExperiment(): Array<CrazyEggExperiment> {
  // This is all pretty sketchy. These APIs are undocumented and were reverse
  // engineered from the CrazyEgg browser library. It is faithfully reproducing
  // their logic to determine which variant is active.
  const experiments: Array<CrazyEggExperiment> = [];
  try {
    const flowIDs = Object.keys(window.CE2.flows);
    for (let i = 0; i < flowIDs.length; i++) {
      const flowID = flowIDs[i];
      const flow = window.CE2.flows[flowID];
      if (flow.status !== 'running') {
        continue;
      }
      const deviceType = DEVICE_TYPE[window.CE2.clock.getDeviceType()];
      if (flow.deviceType !== deviceType && !flow.deviceTypes?.includes(deviceType)) {
        continue;
      }
      if (!window.CE2.invoke(flow.match)) {
        continue;
      }
      const activeVariant = window.CE2.getVariant(flowID);
      const activeVariantId = activeVariant.variant.realId;
      experiments.push({
        id: flow.id,
        name: flow.name,
        variantType: activeVariant.variant.type,
        variantId: `${activeVariantId}`,
      });
    }
  } catch (err) {
    logger.warn('[TRANSFORM - CRAZY EGG] Failed to create page payload', err);
  }
  return experiments;
}

export function registerCEPageTransform(router: Router) {
  const experiments = getExperiment();
  const experimentPayload = experiments.reduce(
    (p, exp) => {
      const experimentName = camelCase(exp.name);
      const variantName = exp.variantType == 'control' ? 'control' : exp.variantId;
      p.ceExperiments.push(experimentName);
      p[`ce${camelCase(exp.name, { pascalCase: true })}`] = variantName;
      return p;
    },
    { ceExperiments: [] } as CrazyEggEventPayload,
  );
  router.registerTransform('page-ce', (event, payload) => {
    return {
      ...payload,
      properties: {
        ...payload.properties,
        ...experimentPayload,
      },
    };
  });
}
