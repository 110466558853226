import logger from '@remento-infrastructure/common/logger';

import { Debugger } from '../../debugger.js';
import { Payload, Router } from '../../router.js';

import { EmailFormSubmitEvent, EmailFormSubmitPayload } from './form.types.js';

export function extractFormPayload($form: HTMLDivElement): Payload<EmailFormSubmitPayload> | null {
  const formType = $form.dataset.analyticsType;
  if (formType == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return null;
  }
  const formListId = $form.dataset.analyticsListId;
  const $emailInput = $form.querySelector('[name|="email"]') as HTMLInputElement;
  if ($emailInput == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return null;
  }
  return {
    data: {
      email: $emailInput.value,
      formType: formType ?? 'unknown',
      formListId,
    },
    properties: {},
  };
}

export function registerWebflowEmailFormElementObserver(router: Router, $form: HTMLDivElement) {
  const $formContainer = $form?.parentElement;
  if ($form == null || $formContainer == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return;
  }
  $formContainer.classList.remove('w-form');
  const $submitButton = $form.querySelector('[type="submit"]') as HTMLButtonElement;
  if ($submitButton == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return;
  }

  const $successDiv = $formContainer.querySelector('.w-form-done') as HTMLDivElement;
  if ($successDiv == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return;
  }
  const $errorDiv = $formContainer.querySelector('.w-form-fail') as HTMLDivElement;
  if ($errorDiv == null) {
    console.warn('[OBSERVER - FORM - WEBFLOW] Invalid webflow form', $form);
    return;
  }
  $submitButton.addEventListener('click', async () => {
    $submitButton.disabled = true;
    $submitButton.value = $submitButton.attributes.getNamedItem('data-wait')?.value ?? 'Please wait...';
    try {
      const payload = extractFormPayload($form);
      if (payload == null) {
        return null;
      }
      logger.debug(`[OBSERVER - FORM - WEBFLOW] Publishing event ${EmailFormSubmitEvent}`, payload);
      await router.publish(EmailFormSubmitEvent, payload);
      $form.style.display = 'none';
      $successDiv.style.display = 'block';
    } catch (err) {
      $form.style.display = 'none';
      $errorDiv.style.display = 'block';
    }
  });
}

export function registerWebflowEmailFormsObserver(router: Router, debug?: Debugger) {
  if (!router.registerPublisher('email-form-webflow-observer')) {
    console.warn('[OBSERVER - FORM - WEBFLOW] The hook is already active');
    return;
  }
  const $emailForms = document.querySelectorAll('#email-form');
  for (let i = 0; i < $emailForms.length; i++) {
    registerWebflowEmailFormElementObserver(router, $emailForms.item(i) as HTMLDivElement);
  }
  debug?.registerTooltips('email-form-webflow-observer', () => ({
    elements: Array.from(document.querySelectorAll('#email-form [type="submit"]') as NodeListOf<HTMLElement>),
    getEvent: ($element) => {
      const $form = $element.closest('#email-form') as HTMLDivElement | null;
      const payload = $form == null ? null : extractFormPayload($form);
      return { type: 'website.form', payload, identify: payload?.data.email };
    },
  }));
}
