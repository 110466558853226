import { BrowserAnalyticsClient } from '@remento-infrastructure/analytics-client/client.js';

import { waitForUBInit } from './unbounce.js';

export function disableUBDialogs() {
  window.ube.__embeddables.filter((emb) => emb.display.name === 'dialog').forEach((emb) => (emb.status = 'cancelled'));
}

export async function disableUBDialogsOnIdentify(client: BrowserAnalyticsClient) {
  await waitForUBInit();
  if (client.isIdentified()) {
    disableUBDialogs();
  } else {
    client.onIdentify(() => disableUBDialogs());
  }
}
