export async function waitForProperty(obj: Record<string, any>, property: string): Promise<void> {
  if (obj[property]) {
    return;
  }
  return new Promise<void>((resolve) => {
    let value: any = undefined;
    Object.defineProperty(obj, property, {
      get() {
        return value;
      },
      set(v) {
        value = v;
        resolve();
      },
      enumerable: true,
      configurable: true,
    });
  });
}
