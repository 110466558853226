import { waitForProperty } from './proxy.js';

/**
 * Wait for the window.ube variable to be set. There is no way to hook into the unbounce code so it sets
 * up a property on the window to intercept when unbounce defines it.
 */
export async function waitForUBInit(): Promise<void> {
  if (window.ube) {
    return;
  }
  await waitForProperty(window, 'ube');
  await waitForProperty(window.ube, '__embeddables');
}
