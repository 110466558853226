import { IframeServer } from '@remento-infrastructure/common/iframe.server';

import { Router } from '../../router.js';

import { IFrameAnalyticsEvent, IFrameAnalyticsEventPayload } from './iframe.types.js';

export function registerIFramesObserver(router: Router, iFrameServer: IframeServer) {
  iFrameServer.addHandler(IFrameAnalyticsEvent, async (request: IFrameAnalyticsEventPayload): Promise<boolean> => {
    return await router.publish(request.event, request.payload);
  });
}
