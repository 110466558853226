import '@remento-infrastructure/analytics-client/browser.js';
import '@remento-infrastructure/analytics-integrations/@types.js';

import { RumInitConfiguration } from '@datadog/browser-rum';
import { AnalyticsConfig } from '@remento-infrastructure/analytics-integrations/@types.js';
import { getRuntimeConfig, initializeAnalytics } from '@remento-infrastructure/analytics-integrations/init.js';
import { registerActivityHandler } from '@remento-infrastructure/analytics-integrations/sources/activity/activity-handler.js';
import { registerActivityPlugin } from '@remento-infrastructure/analytics-integrations/sources/activity/activity-plugin.js';
import { registerClickHandler } from '@remento-infrastructure/analytics-integrations/sources/click/click-handler.js';
import { registerClickObserver } from '@remento-infrastructure/analytics-integrations/sources/click/click-observer.js';
import { registerEmailFormHandler } from '@remento-infrastructure/analytics-integrations/sources/form/form-handler.js';
import { registerWebflowEmailFormsObserver } from '@remento-infrastructure/analytics-integrations/sources/form/form-observer-webflow.js';
import { registerIFramesObserver } from '@remento-infrastructure/analytics-integrations/sources/iframe/iframe-observer.js';
import { registerActivityTransform } from '@remento-infrastructure/analytics-integrations/transforms/activity.js';
import { registerCookiesTransform } from '@remento-infrastructure/analytics-integrations/transforms/cookies.js';
import { registerPageTransform } from '@remento-infrastructure/analytics-integrations/transforms/page.js';
import { registerCEPageTransform } from '@remento-infrastructure/analytics-integrations/transforms/page-crazy-egg.js';
import { waitForCELoaded } from '@remento-infrastructure/integrations/crazy-egg.js';
import {waitForWisepopsLoaded} from "@remento-infrastructure/integrations/wisepops.js";
import {
  registerWisepopsEmailFormsObserver
} from "@remento-infrastructure/analytics-integrations/sources/form/form-observer-wisepops.js";

const ANALYTICS_CONFIG: AnalyticsConfig = {
  clientSegmentKey: 'h8r0ZSVugFZqleYSJOzpnY00jORC9oP7',
  clientSource: 'remento-main-web',
  clientAPIHost: 'segment.remento.co',
  clientCDNUrl: 'https://segment-cdn.remento.co',
  debugConfig:
    getRuntimeConfig('debug', null) == null
      ? null
      : {
          bootstrap: {
            url: 'https://storage.googleapis.com/remento-infrastructure-cdn/website/remento-co/bootstrap-custom.css',
            namespace: 'tw-bt',
          },
        },
  iframeServerConfig: {
    domains: ['remento.co', 'webflow.io', 'ubembed.com'],
  },
  loggerLevel: getRuntimeConfig('logger-level', null),
};

const DATADOG_CONFIG: RumInitConfiguration = {
  applicationId: '18b33a9d-7117-494b-9910-1dc203241dfc',
  clientToken: 'pub25e2529949e7ecc0d55790836150b10d',
  site: 'us5.datadoghq.com',
  service: 'remento-main-web',
  env: new URL(document.URL).hostname.endsWith('remento.co') ? 'production' : 'development',
  sessionSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
};

initializeAnalytics(ANALYTICS_CONFIG, DATADOG_CONFIG, (context) => {
  // Ideally the CE transform is registered before the activity plugin is initialized
  // so that the page event has the CE properties. To protect against issues when CE
  // fails to initialize, there is a somewhat aggressive timeout. If CE fails to load,
  // we bootstrap everything else and we register the CE transform whenever CE
  // finally initializes.
  waitForCELoaded()
    .then(() => {
      registerCEPageTransform(context.router);
    })
    .catch(() => {
      // Wait for CE without any timeout, register the transform once it actually loads
      waitForCELoaded(null).then(() => registerCEPageTransform(context.router));
    })
    .finally(() => {
      registerActivityPlugin(context, 20 * 1000, 10 * 1000);

      registerCookiesTransform(context.router);
      registerPageTransform(context.router, ANALYTICS_CONFIG.clientSource);
      registerActivityTransform(context.router);

      registerClickHandler(context);
      registerEmailFormHandler(context);
      registerActivityHandler(context);

      registerClickObserver(context.router, context.debugger);
      registerWebflowEmailFormsObserver(context.router, context.debugger);
      if (context.iframeServer) {
        registerIFramesObserver(context.router, context.iframeServer);
      }
      waitForWisepopsLoaded().then(() => registerWisepopsEmailFormsObserver(context.router, context.debugger));
    });
});
