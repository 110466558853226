import { registerCSSStyles } from './css.js';
import { waitForUBInit } from './unbounce.js';

const UB_STICKYBAR_STYLES = `
.ub-emb-bar.ub-emb-visible .ub-emb-close {
  display: none;
}
`;

function moveNavBar() {
  // It takes 300 ms for the sticky bar to animate. It does push the nav bar down until the user scroll
  // so it's not an issue if we wait a little too long. We shouldn't wait so long that the user has time to scroll.
  setTimeout(() => {
    const $stickyBar = document.querySelector('.ub-emb-bar.ub-emb-visible .ub-emb-bar-frame') as HTMLDivElement;
    const $stickyNav = document.querySelectorAll('.sticky-nav') as NodeListOf<HTMLDivElement>;
    if ($stickyBar != null) {
      $stickyNav.forEach(($child) => {
        // Move the sticky nav down based on the height of the sticky bar
        $child.style.top = `${$stickyBar.clientHeight}px`;
      });
    }
  }, 300);
}

export async function configureUBStickyBar() {
  await waitForUBInit();

  // We wait for the stick bar to show up
  const intervalHandle = setInterval(() => {
    const stickyBar = window.ube.__embeddables.find((s) => s.display.name === 'stickyBar' && s.status == 'ready');
    if (stickyBar != null) {
      // The sticky bar is ready, stop trying to find it and move the sticky nav bar
      clearInterval(intervalHandle);
      moveNavBar();
    }
    // The sticky bar takes 300ms to animate.
  }, 50);
  // If we can't find a sticky bar for 5 seconds, stop trying
  setTimeout(() => clearInterval(intervalHandle), 5000);

  // Register styles to hide the sticky bar close button
  registerCSSStyles(UB_STICKYBAR_STYLES);
}
