import logger from '@remento-infrastructure/common/logger.js';

export function waitForCELoaded(timeout: number | null = 2000): Promise<void> {
  return new Promise((resolve, reject) => {
    let finalized = false;
    const timeoutHandle =
      timeout == null
        ? null
        : setTimeout(() => {
            if (finalized) {
              return;
            }
            finalized = true;
            logger.error('[CRAZY EGG] Failed to initialize before timeout');
            reject(new Error('crazy-egg-timeout'));
          }, timeout);
    (window.CE_API || (window.CE_API = [])).push(function () {
      if (finalized) {
        logger.warn('[CRAZY EGG] Initialized after timeout');
        return;
      }
      logger.debug('[CRAZY EGG] Initialized');
      if (timeoutHandle != null) {
        clearTimeout(timeoutHandle);
      }
      finalized = true;
      setTimeout(resolve, 0);
    });
  });
}
